@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Merriweather', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Reset */

a {
  text-decoration: none;
  color          : black;
}

a:hover {
  text-decoration: underline;
  color          : black
}

a:hover.dark {
  text-decoration: underline;
  color          : white;
}

p {
  font-size  : 19px;
  line-height: 36px;
  font-weight: 400;
}

h1 {
  line-height: 48px;
}

.title {
  font-size: 40px;
}

input {
  font-family: 'Merriweather', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

@media only screen and (max-width: 600px) {
  p {
    font-size  : 16px;
    line-height: 32px;
    font-weight: 400;
  }

  .title {
    font-size: 28px;
    font-weight: 800 !important;
  }

  h1 {
    line-height: 48px;
  }
  
}